import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function () {
  return (
    <TicketshopPage
      description={
        <div>
          <h2 className="mb-4">It's coming home</h2>
        </div>
      }
      title="It's coming home"
      imageSrc={""}
      backgroundSrc="/coming-home.jpg"
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?callback=fixrApiCallback&salesChannel=whitelabel-website" data-fixr-shop-id="77358234-320b-48bd-8c0c-bf33aecef89c" data-fixr-theme="light" data-name-filter="coming home"></script>`}
    />
  );
}
